import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import axios from 'axios';
import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';

import { getAuth } from '../auth/auth.helpers';

Vue.use(VueToast);

const CONFIG = new Config();

export const listUsuarioPortalByGrupoEconomico = async (gestorLogin) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const { token } = getAuth();
      var data = JSON.stringify({
        login: gestorLogin
      });

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}/usuario/portal/listUsuarioPortalByGrupoEconomico`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 300);
  });
};
