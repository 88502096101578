<template>
  <main id="associateUser">
    <PageHeader
      :title="$t('management.associate_user.title')"
      :items="breadcrumb"
    />

    <b-row>
      <b-col v-if="this.emptyMessage" class="text-center p-2">
        <b-card>
          <h5>USUÁRIOS NÃO ENCONTRADAS.</h5>
        </b-card>
      </b-col>
      <b-col v-if="this.InitialLoading" class="text-center p-2">
        <b-card>
          <b-spinner v-if="this.InitialLoading" label="Spinning"></b-spinner>
        </b-card>
      </b-col>
      <b-col v-if="!this.InitialLoading && !this.emptyMessage">
        <b-card>
          <b-form
            @submit.prevent="tryToLogInAsUser(selectedUser)"
            inline
            class="d-flex justify-content-center"
          >
            <label for="inline-form-custom-select-pref">
              {{ $t('management.associate_user.user_name') }}
            </label>

            <b-form-select
              v-model="selectedUser"
              class="m-2"
              id="inline-form-custom-select-pref"
            >
              <b-form-select-option :value="null" disabled
                >-- Selecione um usuário --</b-form-select-option
              >
              <b-form-select-option-group
                :label="empresa.nomeGrupoEconomico"
                v-for="empresa in valuesUser"
                :key="empresa.nomeGrupoEconomico"
              >
                <b-form-select-option
                  v-for="usr in empresa.usuarios"
                  :value="usr"
                  :key="usr"
                  >{{ usr }}</b-form-select-option
                >
              </b-form-select-option-group>
            </b-form-select>

            <b-button variant="primary" type="submit">{{
              $t('management.associate_user.login_user')
            }}</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-col v-if="this.loading === true" class="text-center p-2">
      <b-card>
        <b-spinner v-if="this.loading === true" label="Spinning"></b-spinner>
      </b-card>
    </b-col>
  </main>
</template>
<script>
import VueCookies from 'vue-cookies';
import PageHeader from '@/components/page-header';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import Vue from 'vue';

import { getAuth } from '../auth/auth.helpers';
import { i18n } from '../../main';
import { listUsuarioPortalByGrupoEconomico } from './associateUser.services';
import { createNamespacedHelpers } from 'vuex';
import router from '@/router';

const { mapActions } = createNamespacedHelpers('auth');

import 'vue-toast-notification/dist/theme-sugar.css';

const { user } = getAuth();
const CONFIG = new Config();

const loginGestor = user.login;

Vue.use(VueCookies);
Vue.use(VueToast);
let vm;
export default {
  name: 'associateUser',
  components: {
    PageHeader
  },
  data() {
    return {
      title: i18n.tc('management.associate_user.title'),
      selectedUser: null,
      valuesUser: [],
      usuariosGestor: [],
      InitialLoading: false,
      emptyMessage: false,
      loading: false,
      url: null,
      config: CONFIG
    };
  },
  mounted() {
    vm = this;
  },
  created() {
    this.InitialLoading = true;
    this.hasUsers = false;

    listUsuarioPortalByGrupoEconomico(loginGestor)
      .then((response) => {
        if (response.length == 0) {
          this.emptyMessage = true;
          this.InitialLoading = false;
          this.loading = false;
          this.hasUsers = true;
        }

        this.valuesUser = response.data;
        // this.selectedUser = null;
        this.InitialLoading = false;
      })
      .catch(() => {
        this.InitialLoading = false;
        this.loading = false;

        Vue.$toast.error('Erro ao listar usuários', {
          position: 'top-right',
          duration: 5000
        });
      });
  },
  computed: {
    breadcrumb: () => [
      {
        text: 'Home',
        href: '/'
      },
      {
        text: i18n.tc('management.associate_user.title'),
        active: true
      }
    ],

    fields: () => [
      {
        key: 'nomeUsuario',
        label: i18n.tc('management.associate_user.user_name')
      },
      {
        key: 'idUsuario',
        label: 'ID',
        sortable: false
      },
      {
        key: 'button',
        label: i18n.tc('management.associate_user.login_user'),
        sortable: false
      }
    ]
  },
  methods: {
    ...mapActions(['LOGIN_GESTOR']),
    tryToLogInAsUser(userLogin) {
      this.LOGIN_GESTOR({
        loginUsuario: userLogin
      })
        .then((_response) => {
          if (_response.isAuthenticated) {
            // navega para a home
            router.replace({
              name: 'home'
            });
            // atualiza a página para renderizar componentes
            this.$router.go(0);
          } else {
            this.isAuthError = true;
            this.authError = vm.$t('login.invalid');
          }
        })
        .catch(() => {
          this.authError = vm.$t('login.error');
          this.isAuthError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
@media (max-width: 536px) {
  .btn {
    width: 128px;
    margin: 5px;
  }
}

@media (max-width: 1150px) {
  .col-table,
  .col-chart {
    width: 100%;
  }
}

td {
  font-size: 13px;
}

th {
  font-size: 13px;
}

.col-table {
  width: 30%;
}

.col-chart {
  width: 70%;
}
</style>
